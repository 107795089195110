import { useCallback } from 'react';
import { refetchTranslationStatus, setPendingToTranslationStatus } from '@biz/query'
import { useTranslateMutation } from './useTranslateMutation'
export const useTranslateStart = () => {
  const refetch = refetchTranslationStatus()
  const setPending = setPendingToTranslationStatus()
  const translate = useTranslateMutation()
  return useCallback((creationDataId: number | undefined) => {
    if (creationDataId !== undefined) {
      setPending({ id: creationDataId })
      translate.mutateAsync({ id: creationDataId }).then(refetch)
    }
  }, [])
}
