import { Button } from 'antd'
import { CampaignCaption } from '@biz/ui'
import styles from './LocationTypeSwitch.module.scss'

type LocationTypeSwitchFunction = {
  value?: boolean
  onChange?: (value: boolean) => void
}
export const LocationTypeSwitch: React.FC<LocationTypeSwitchFunction> = ({ value, onChange }) => (
  <div className={styles.container}>
    <CampaignCaption
      isSub
      tooltip={`대한민국과 대한민국 외 국가는 동시 선택할 수 없어요.\n국내용 캠페인, 해외용 캠페인을 따로 생성해 주세요.`}
    >
      {'국가 유형'}
    </CampaignCaption>
    <div className={styles.message}>
      {'글로벌 국가에 광고 해보세요. 글로벌 와디즈 관심 유저, 유사 타겟 유저로 메타 머신러신에 의해 광고가 진행됩니다.'}
    </div>
    <div className={styles.buttonContainer}>
      <Button className={value ? styles.on : styles.off} onClick={() => onChange?.(true)}>
        {'한국'}
      </Button>
      <Button className={value ? styles.off : styles.on} onClick={() => onChange?.(false)}>
        {'한국 외 국가'}
      </Button>
    </div>
  </div>
)

