import { useQuery, type QueryClient } from '@tanstack/react-query'
import { fetchRecommendCountries } from '@biz/api'

const queryKey = ['@apps/biz-center/NationSelectModal/RecommendCountries']
const queryOption = {
  queryKey,
  queryFn: () => fetchRecommendCountries().then((res) => res.data),
}

export const useRecommendCountriesQuery = () => useQuery(queryOption)
export const loadRecommendCountries = (queryClient: QueryClient) => async () =>
  queryClient.getQueryData(queryKey) ?? (await queryClient.fetchQuery(queryOption))
