import { useState } from 'react'
import { Button } from 'antd'
import { ReactComponent as CheckIcon } from '@wadiz-frontend/waffle-artworks/check.svg'
import { TranslationResultModal } from './TranslationResultModal'
import styles from './TranslateCover.module.scss'

type TranslateCoverFunction = {
  creationDataId: number
}
export const TranslateCoverDone: React.FC<TranslateCoverFunction> = ({ creationDataId }) => {
  const [isOpen, setOpen] = useState<boolean>(false) // modal open/close
  return (
    <div className={styles.containTranslateCover}>
      <CheckIcon />
      <div>
        <h5>{'AI 번역이 완료되었어요!'}</h5>
        <div className={styles.notify}>{`번역 결과를 확인하고,\n번역된 내용을 소재에 반영해 보세요.`}</div>
      </div>
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setOpen(true)}>
          {'번역 결과 확인하기'}
        </Button>
      </div>
      <TranslationResultModal creationDataId={creationDataId} isOpen={isOpen} onClose={() => setOpen(false)} />
    </div>
  )
}
