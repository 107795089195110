import { axios } from '../axios'
import type { TranslationStatusType } from './TranslationStatusType'

//https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732913716/-+API#%EB%B2%88%EC%97%AD-%EC%A4%91%EB%8B%A8-%EC%9A%94%EC%B2%AD
type ResponseType = {
  ok: boolean
  data:
    | {
        translateReqId: number // 번역 요청 아이디 '1234
        translateStatus: TranslationStatusType //번역 상태 ( “ING”:번역진행중 )
        translateReqLimit: string // 번역요청 카운트 ( 현재요청수/최대 요청수) - 캠페인 단위
      }
    | string // "이미 완료되었거나 취소된 번역 요청입니다. 현재 상태: 번역완료"
}
export const fetchPostTranslationCancel = (creationDataId: number): Promise<ResponseType> =>
  axios.post(`/api/camp/make02/translate/${creationDataId}/cancel`)
