// apps/biz-center/src/pages/TargetCreateLayout/TargetCreatePage 아래
// Form.Item 이름들을 정리하는 파일입니다. 원래는 각 디렉토리에서 정리했으나
// form 영역을 넓게 잡다보니 이를 따로 관리하게 되었습니다.
export const targetFormRegistry = {
  CampaignNameOfName: 'campaignName',
  ProjectSelectorName: 'projectSelect',
  CampaignRangeName: 'range',
  CampaignMediaName: 'campaignMedia',
  CampaignTotalName: 'totalCost',
  FundingTypeItemName: 'projectFundingDiv',

  GenderSelectorName: 'gender',
  AgeRangePickerName: 'age',
  IsLocalSwitchName: 'isLocal',
  LocationSelectorName: 'location',
  CountriesSelectorName: 'countries',

  CreativeAssetsName: 'creatives',
  CreativeAssetsId: 'id',
  CTASelectorName: 'cta',
  ImageManagerName: 'imageList',
  InputAdCopyName: 'mainCopy',
  InputAdDescriptionName: 'description',
  InputAdTitleName: 'title',
} as const
