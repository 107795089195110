import { axios } from '../axios'
import { dummyProjectList } from './dummyProjectList'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16487514113/....+-+API#%EC%83%88-%EC%BA%A0%ED%8E%98%EC%9D%B8-%EC%83%9D%EC%84%B1
export type ProjectInfoType = {
  id: string // 'F26767'
  projectNm: string // '[26767] 디자인 광고 오픈예정 펀딩 ej+ad1'
  userNo: string // '9998889970'
  projectStatus: string // 'STANDBY'
  bizDiv: string // 'biz'
  projectDiv: string // 'FUNDING'
  projectCat: string // 'CULTURE_ARTIST'
  projectLu: string // 'https://www.wadiz.kr/web/store/detail/4429'
  adPostStartDate: string
  adPossEndDate: string // '20241026'
  rcsPostDate: string // '20240906'
  rcsEndDate: string // '20240918'
  rcsStatus: string // 'IN_PROGRESS'
  screeningStatus: string // 'STORY_APPROVAL'
  recipientEmail: string // 'testQA@wadiz.kr'
  representativeName: string // '테스트'
  businessName: string // '광고_테스트ad2'

  adPossStartTime: string
  adPossEndTime: string // '20241026235900'
  rcsEndTime: string // '20240918110000'
  rcsPostTime: string // '20240906143500'

  isAdultContent: boolean
  submittedDate: string

  isAIApprove: boolean // AI 자동검수 대상 여부, true: AI 자동검수대상, false: 관리자 검수 대상
  shippingCountries: string // 기존 데이터는 항목이 없을 수 있습니다. (응답시 Field 없음)
  // 배송가능 국가 목록
  // 쉽표(,) 로 구분된 국가 목록 코드
  // ISO 표준의 2자리 대문자 국가 코드
  // KR 은 Default
}

type FunctionType = () => Promise<Partial<ProjectInfoType>[]>
export const fetchProjectList: FunctionType = () => axios.get(`/api/maker/projects`)
