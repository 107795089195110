import { Tip } from './Tip'
import styles from './CampaignRange.module.scss'

const isLive = import.meta.env.VITE_ENV == 'prod'
export const BottomCaption = () => (
  <div className={styles.content}>
    <Tip>{'캠페인 기간을 최소 5일 이상으로 설정해야 성과를 높일 수 있어요!'}</Tip>
    <ul className={styles.bottomContent}>
      <li>{'와디즈 타겟 광고는 광고 성과를 높이기 위해 머신러닝 기술을 사용합니다.'}</li>
      <li>{'머신러닝은 5일이 지나야 최적화된 타겟 유저를 찾을 수 있어요.'}</li>
    </ul>
    {!isLive && (
      <>
        <div style={{ height: 16 }} />
        <Tip>{'타겟 국가에 맞는 캠페인 일정을 설정해 주세요.'}</Tip>
        <ul className={styles.bottomContent}>
          <li>{'비즈센터에서 설정하는 시간은 한국 시간 기준이에요.'}</li>
          <li>{'해외 국가를 타겟으로 진행하는 경우, 타겟 국가에 맞는 일자와 시간을 설정해 주세요.'}</li>
        </ul>
      </>
    )}
  </div>
)
