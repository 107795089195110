import { useState } from 'react'
import { Button } from 'antd'
import { ReactComponent as ServiceLoading02Icon } from '@wadiz-frontend/waffle-artworks/service-loading-02.svg'
import { ConfirmModal } from '@biz/ui'
import styles from './TranslateCover.module.scss'

type TranslateCoverFunction = {
  onStopTranslate: () => void
  onPreview?: () => void
}
export const TranslateCoverDoing: React.FC<TranslateCoverFunction> = ({ onStopTranslate, onPreview }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  return (
    <div className={styles.containTranslateCover}>
      <ServiceLoading02Icon />
      <div>
        <h5>{'AI 번역이 진행 중 이에요'}</h5>
        <div
          className={styles.notify}
        >{`제작해주신 소재를 영어로 번역하고 있어요.\n번역은 최대 3분까지 소요될 수 있어요.\n잠시만 기다려 주세요.`}</div>
      </div>
      <div className={styles.buttonContainer}>
        <Button type="text" danger size="middle" onClick={() => setOpen(true)}>
          {'번역 중단하기'}
        </Button>
        <Button type="text" onClick={onPreview}>
          {'원문 소재 미리보기'}
        </Button>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        title="AI 번역을 중단하시겠어요?"
        cancel="계속 진행하기"
        onCancel={() => setOpen(false)}
        confirm="번역 중단하기"
        onConfirm={onStopTranslate}
      >
        <pre>{`번역 중단 시 지금까지 번역된 내용은 모두 삭제돼요.\n정말 중단하시겠어요?`}</pre>
      </ConfirmModal>
    </div>
  )
}
