import { axios } from '../axios'
import type { TranslationStatusType } from './TranslationStatusType'

//https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732913716/-+API#%EB%B2%88%EC%97%AD-%EC%9A%94%EC%B2%AD
type ResponseType = {
  ok: boolean
  msg?: string // ok가 false라면 메시지를 심어줌
  data: {
    translateReqId: number // 번역 요청 아이디 '1234
    translateReqLimit: string // 번역요청 카운트 ( 현재요청수/최대 요청수) - 캠페인 단위
    translateStatus: TranslationStatusType //번역 상태 ( “ING”:번역진행중 )
  }
}
export const fetchPostTranslationRequest = (creationDataId: number): Promise<ResponseType> =>
  axios.post(`/api/camp/make02/translate/${creationDataId}`)
