import { ReactComponent as InfoIcon } from '@wadiz-frontend/waffle-system-icons/info.svg'
import styles from './TranslationResultHelperMessageBox.module.scss'

export const TranslationResultHelperMessageBox: React.FC = () => (
  <div className={styles.containTranslationResultHelperMessageBox}>
    <h5>
      <InfoIcon />
      <span>{'AI 번역 결과를 적용하기 전에 참고해 주세요!'}</span>
    </h5>
    <div>
      AI 번역 기능은 영어 광고 소재를 쉽게 제작할 수 있도록 돕는 보조 도구에요. 소재의 특성에 따라 번역 결과가 다소
      아쉬운 완성도로 제공될 수 있으니 참고하여 진행해 주세요.
    </div>
  </div>
)

