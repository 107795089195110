import { useTranslationStatusQuery } from '@biz/query'
import { TranslateCoverDoing } from './TranslateCoverDoing'
import { TranslateCoverError } from './TranslateCoverError'
import { TranslateCoverDone } from './TranslateCoverDone'
import { TranslateCoverPending } from './TranslateCoverPending'
import { useTranslateCancelMutation } from './useTranslateCancelMutation'

type TranslateCoverFunction = {
  creationDataId: number
  onTranslate: () => void
  onPreview?: () => void
}
export const TranslateCover: React.FC<TranslateCoverFunction> = ({ creationDataId, onPreview, onTranslate }) => {
  const { data } = useTranslationStatusQuery({ id: creationDataId })
  const { translateStatus: status } = data || { translateStatus: 'REQUESTED' }
  const translateCancel = useTranslateCancelMutation()
  const onStopTranslate = () => translateCancel.mutateAsync({ id: creationDataId })

  return (
    <>
      {status === 'PENDING' && <TranslateCoverPending />}
      {status === 'PROCESSING' && <TranslateCoverDoing onStopTranslate={onStopTranslate} onPreview={onPreview} />}
      {status === 'COMPLETED' && <TranslateCoverDone creationDataId={creationDataId} />}
      {status === 'CANCELED' && <TranslateCoverError onTranslate={onTranslate} onStopTranslate={onStopTranslate} />}
    </>
  )
}
