import React, { forwardRef } from 'react'
import cx from 'classnames'

import styles from './Textarea.module.scss'

export interface TextareaProps<T = HTMLTextAreaElement> extends React.TextareaHTMLAttributes<T> {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  status?: 'error'
  className?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ size, status, className, ...props }, ref) => {
  const classes = cx(
    styles.textarea,
    size && {
      [styles.sm]: size === 'sm',
      [styles.md]: size === 'md',
      [styles.lg]: size === 'lg',
      [styles.xl]: size === 'xl',
    },
    {
      [styles.error]: status === 'error',
    },
    className
  )
  return <textarea {...props} className={classes} ref={ref} />
})

export default React.memo(Textarea)
