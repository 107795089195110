import { redirect, type RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import type { GeoInfoType, CampaignInfoType } from '@biz/api'
import {
  loadProjectList,
  setCurrentCampaignId,
  loadCampaignInfo,
  loadAdditionalCampaignInfo,
  overrideCachedList,
  loadCreationEditingCheck,
  resetCampaignStatus,
  silentUpdateCreativeData,
} from '@biz/query'
import { targetFormRegistry, makeCreativeAssetField } from '@biz/ui'
import { DefaultLayout } from '../DefaultLayout'
import { TargetCreatePage } from './TargetCreatePage'

const {
  CampaignNameOfName,
  ProjectSelectorName,
  CampaignRangeName,
  CampaignMediaName,
  CampaignTotalName,
  FundingTypeItemName,
  GenderSelectorName,
  AgeRangePickerName,
  IsLocalSwitchName,
  LocationSelectorName,
  CountriesSelectorName,
  CreativeAssetsName,
} = targetFormRegistry

const defaultGeoInfo: GeoInfoType[] = [
  {
    countryCode: 'KR',
    countryName: '대한민국',
    key: 'KR',
    name: '대한민국',
    supportsCity: true,
    supportsRegion: true,
    type: 'country',
  } as GeoInfoType,
]
const campaignLoader = async (queryClient: QueryClient, step: string, campaignData: CampaignInfoType) => {
  const {
    projectId,
    campNm,
    campStartTime,
    campEndTime,
    campBudgetTotal1,
    projectFundingDiv /*adminCampYn, campViewStatus*/,
    isGlobal,
    mediaDiv4,
  } = campaignData
  const { genderDiv, location, age } = await loadAdditionalCampaignInfo(queryClient)()
  const parsedLocation: GeoInfoType[] = JSON.parse(location || '[]')
  const locationList = parsedLocation.length > 0 ? parsedLocation : defaultGeoInfo
  await overrideCachedList(queryClient)(locationList)
  const isEditingCreation = await loadCreationEditingCheck(queryClient)()

  const creativeAssets = (await silentUpdateCreativeData(queryClient)) || []
  return {
    tabIndex: step,
    initData: {
      [CampaignNameOfName]: campNm,
      [ProjectSelectorName]: projectId,
      [CampaignRangeName]: [dayjs(campStartTime), dayjs(campEndTime)],
      [CampaignMediaName]: mediaDiv4,
      [CampaignTotalName]: campBudgetTotal1,
      [GenderSelectorName]: genderDiv === 'ALL' ? ['MALE', 'FEMALE'] : genderDiv.split(','),
      [AgeRangePickerName]: age.split(',').map((n) => Number(n.replace('+', ''))),
      [IsLocalSwitchName]: !isGlobal,
      [LocationSelectorName]: isGlobal ? undefined : locationList.map((l) => l.name),
      [CountriesSelectorName]: isGlobal ? locationList.map((l) => l.countryCode) : undefined,
      [CreativeAssetsName]: creativeAssets.map(makeCreativeAssetField),
      [FundingTypeItemName]: projectFundingDiv,
    },
    isEditingCreation,
  }
}

type FunctionType = (queryClient: QueryClient) => RouteObject
export const getTargetAdRoute: FunctionType = (queryClient: QueryClient) => ({
  element: <DefaultLayout />,
  loader: async () => {
    await loadProjectList(queryClient)()
    return null
  },
  children: [
    {
      path: '/camp/make02',
      loader: async () => {
        resetCampaignStatus(queryClient)
        await setCurrentCampaignId(queryClient)(null) // reset
        const prefix = import.meta.env.VITE_WADIZ_CAMPAIGN_PREFIX
        await overrideCachedList(queryClient)(defaultGeoInfo)
        return {
          tabIndex: 'step01',
          initData: {
            [CampaignNameOfName]: `${prefix ? '##테스트_' + prefix : '임시'}_${dayjs().format('YYYYMMDDHHMM')}`,
            [ProjectSelectorName]: '',
            [CampaignRangeName]: [dayjs().add(1, 'day').startOf('day'), dayjs().add(2, 'day').endOf('day')],
            [CampaignMediaName]: 'FBITG',
            [CampaignTotalName]: 300000,
            [GenderSelectorName]: ['MALE', 'FEMALE'],
            [AgeRangePickerName]: [18, 65],
            [IsLocalSwitchName]: true,
            [LocationSelectorName]: defaultGeoInfo.map((l) => l.name),
            [CreativeAssetsName]: [],
          },
        }
      },
      element: <TargetCreatePage title={'새 캠페인 만들기'} />,
    },
    {
      path: '/mngCamp/mngCamp/mng/:campId',
      loader: async ({ params }) => {
        resetCampaignStatus(queryClient)
        const { campId } = params
        if (campId === undefined) {
          toast.error('캠페인이 지정되지 않았습니다.')
          return redirect('/mngCamp/mngCamp') // 리스트
        }
        await setCurrentCampaignId(queryClient)(Number(campId))
        const { data: campaignData } = (await loadCampaignInfo(queryClient)()) || {}
        return await campaignLoader(queryClient, 'step01', { ...campaignData, mediaDiv4: 'FBITG' })
      },
      element: <TargetCreatePage title={'캠페인 관리'} />,
    },
    {
      path: '/mngCamp/mngCamp/mng/:campId/:step/:mediaDiv4/:adminCampYn/:campViewStatus/:projectId',
      loader: async ({ params }) => {
        resetCampaignStatus(queryClient)
        const { campId, step, mediaDiv4, /*adminCampYn,*/ /*campViewStatus, */ projectId } = params
        if (campId === undefined) {
          toast.error('캠페인이 지정되지 않았습니다.')
          return redirect('/mngCamp/mngCamp') // 리스트
        }
        if (projectId === undefined || projectId === 'null' || projectId === 'undefined') {
          toast.error('메이커님! 임시저장 캠페인의 설정기간이 만료되었습니다. 새로운 캠페인을 세팅해주세요.')
          return redirect('/mngCamp/mngCamp') // 리스트
        }
        await setCurrentCampaignId(queryClient)(Number(campId))
        const { data: campaignData } = (await loadCampaignInfo(queryClient)()) || {}
        return await campaignLoader(queryClient, step || 'step01', {
          ...campaignData,
          mediaDiv4: mediaDiv4 || 'FBITG',
          projectId,
        })
      },
      element: <TargetCreatePage title={'캠페인 관리'} />,
    },
  ],
})
