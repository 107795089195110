import { axios } from '../axios'
import type { CountryType } from './CountryType'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732192784/-+API#%EC%B6%94%EC%B2%9C-%EA%B5%AD%EA%B0%80-%EC%A1%B0%ED%9A%8C
type ResponseType = {
  ok: boolean
  data: {
    countries: CountryType[]
  }
}
type FunctionType = () => Promise<ResponseType>
export const fetchRecommendCountries: FunctionType = () => axios.get('/api/camp/make02/recommend-countries')
