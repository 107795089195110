import { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import type { TranslateApplyType } from '@biz/api'
import { ConfirmModal } from '@biz/ui'
import { setPendingToTranslationStatus } from '@biz/query'
import { useTranslationResultQuery } from './useTranslationResultQuery'
import { TranslationResultHelperMessageBox } from './TranslationResultHelperMessageBox'
import { TranslationResultTable, TranslationResultRow, TranslationResultImageRow } from './TranslationResultTable'
import styles from './TranslationResultModal.module.scss'
import { useTranslateApplyMutation } from './useTranslateApplyMutation'
import { useTranslateCancelMutation } from '../useTranslateCancelMutation'

type TranslationResultModalFunction = {
  creationDataId: number
  isOpen: boolean
  onClose?: () => void
}
export const TranslationResultModal: React.FC<TranslationResultModalFunction> = ({
  creationDataId,
  isOpen,
  onClose,
}) => {
  const applyInstant = useTranslateApplyMutation()
  const cancelInstant = useTranslateCancelMutation()
  const { data } = useTranslationResultQuery({ id: creationDataId })
  const {
    translateResultId,
    originAdDesc,
    transAdDesc,
    originAfList,
    transAfList,
    originAdTitle01,
    transAdTitle01,
    originAdTitle02,
    transAdTitle02,
  } = data || {}
  const [checkFlag, setCheckFlag] = useState<TranslateApplyType>({
    translateResultId: translateResultId || 0,
    applyAdTitle01: true,
    applyAdTitle02: true,
    applyAdDesc: true,
    transAfList:
      transAfList?.map(({ translateAfId }) => ({
        translateAfId,
        applyTransAf: true,
      })) || [],
  })
  useEffect(() => {
    setCheckFlag({
      translateResultId: data?.translateResultId || 0,
      applyAdTitle01: true,
      applyAdTitle02: true,
      applyAdDesc: true,
      transAfList:
        data?.transAfList?.map(({ translateAfId }) => ({
          translateAfId,
          applyTransAf: true,
        })) || [],
    })
  }, [data])
  const setPending = setPendingToTranslationStatus()
  const onConfirm = () => {
    setPending({ id: creationDataId })
    applyInstant.mutateAsync({ id: creationDataId, params: checkFlag }).finally(onClose)
  }
  const onCancel = () => {
    setPending({ id: creationDataId })
    cancelInstant.mutateAsync({ id: creationDataId }).finally(onClose)
  }
  const [isOpenCancelModal, setOpenCancelModal] = useState<boolean>(false)
  return (
    <Modal
      width={600}
      open={isOpen}
      onCancel={onClose}
      title={'AI 번역 결과'}
      centered
      className={styles.containTranslationResultModal}
      classNames={{
        content: styles.content,
        body: styles.body,
        header: styles.header,
        footer: styles.footer,
      }}
      footer={[
        <Button type="default" size="large" onClick={() => setOpenCancelModal(true)} block>
          {'번역 취소하기'}
        </Button>,
        <Button type="primary" size="large" onClick={onConfirm} block>
          {'번역 결과 적용하기'}
        </Button>,
      ]}
    >
      <div
        className={styles.headMessage}
      >{`적용하기 버튼 선택 시 선택한 항목들이 소재에 반영돼요.\n반영된 영문 내용은 직접 수정할 수 있어요.`}</div>
      <div className={styles.resultArea}>
        <TranslationResultTable>
          {originAdDesc && transAdDesc && (
            <TranslationResultRow
              title="본문"
              org={originAdDesc}
              trans={transAdDesc}
              value={checkFlag.applyAdDesc}
              onChange={(checked) => setCheckFlag({ ...checkFlag, applyAdDesc: checked })}
            />
          )}
          {originAfList && transAfList && (
            <TranslationResultImageRow
              org={originAfList}
              trans={transAfList}
              value={checkFlag.transAfList}
              onChange={(checkedList) => setCheckFlag({ ...checkFlag, transAfList: checkedList })}
            />
          )}
          {originAdTitle01 && transAdTitle01 && (
            <TranslationResultRow
              title="제목"
              org={originAdTitle01}
              trans={transAdTitle01}
              value={checkFlag.applyAdTitle01}
              onChange={(checked) => setCheckFlag({ ...checkFlag, applyAdTitle01: checked })}
            />
          )}
          {originAdTitle02 && transAdTitle02 && (
            <TranslationResultRow
              title="설명"
              org={originAdTitle02}
              trans={transAdTitle02}
              value={checkFlag.applyAdTitle02}
              onChange={(checked) => setCheckFlag({ ...checkFlag, applyAdTitle02: checked })}
            />
          )}
        </TranslationResultTable>
        <TranslationResultHelperMessageBox />
      </div>
      <ConfirmModal
        isOpen={isOpenCancelModal}
        title="AI 번역을 취소하시겠어요?"
        cancel="다시 확인하기"
        onCancel={() => setOpenCancelModal(false)}
        confirm="번역 취소하기"
        onConfirm={() => {
          setOpenCancelModal(false)
          onCancel()
        }}
      >
        {'번역을 취소하면 번역된 내용은 모두 삭제되고 기존에 작성한 내용으로 돌아가요. 정말 취소하시겠어요?'}
      </ConfirmModal>
    </Modal>
  )
}
