import { axios } from '../axios'
import type { CountryType } from './CountryType'
import type { CountryGroupType } from './CountryGroupType'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732192784/-+API#%EC%A0%84%EC%B2%B4-%EA%B5%AD%EA%B0%80-%EC%A1%B0%ED%9A%8C
type ResponseType = {
  ok: boolean
  data: {
    countries: CountryType[]
    countryGroups: CountryGroupType[]
  }
}
type FunctionType = () => Promise<ResponseType>
export const fetchCountriesInfo: FunctionType = () => axios.get('/api/camp/make02/meta-locations')
