import { Form } from 'antd'
import alertify from 'alertifyjs'
import { useProjectListQuery } from '@biz/query'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import { LocationTypeSwitch } from './LocationTypeSwitch'
import { LocationInput } from './LocationInput'
import { NationSelect } from './NationSelect'

const isLive = import.meta.env.VITE_ENV == 'prod'
const { LocationSelectorName, CountriesSelectorName, ProjectSelectorName, IsLocalSwitchName } = targetFormRegistry
export const LocationSelector: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  const { data: projectList } = useProjectListQuery()
  return (
    <div>
      {!isLive && (
        <CampaignCaption tooltip={'해당 국가 혹은 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.'}>
          {'국가'}
        </CampaignCaption>
      )}
      {!isLive && (
        <Form.Item name={IsLocalSwitchName} style={{ margin: 0 }}>
          <LocationTypeSwitch />
        </Form.Item>
      )}
      <Form.Item dependencies={[ProjectSelectorName, IsLocalSwitchName]} style={{ padding: 0, margin: 0 }}>
        {({ getFieldValue, setFieldValue }) => {
          const isLocal = getFieldValue(IsLocalSwitchName)
          const selectedProjectName = getFieldValue(ProjectSelectorName)
          const project = projectList?.find((n) => n.id === selectedProjectName)
          if (isLocal && getFieldValue(LocationSelectorName) === undefined) {
            alertify.warning('초기화 값으로 대한민국을 선택했어요')
            setFieldValue(LocationSelectorName, ['대한민국'])
          }
          return (
            <>
              {isLocal && (
                <>
                  <CampaignCaption
                    isSub
                    tooltip={
                      "대한민국 외 지역은 설정할 수 없어요. 해외 국가 타겟의 캠페인을 생성하고 싶다면 국가 유형에서 '대한민국 외 국가'를 선택해 주세요"
                    }
                  >
                    {'지역'}
                  </CampaignCaption>
                  <Form.Item name={LocationSelectorName}>
                    <LocationInput onChange={doUpdate} />
                  </Form.Item>
                </>
              )}
              {!isLive && !isLocal && (
                <Form.Item name={CountriesSelectorName}>
                  <NationSelect
                    onChange={doUpdate}
                    onCancel={() => {
                      alertify.warning("'한국 외 국가' 선택을 취소 했어요.")
                      setFieldValue(IsLocalSwitchName, true)
                    }}
                    shippingCountries={project?.shippingCountries}
                  />
                </Form.Item>
              )}
            </>
          )
        }}
      </Form.Item>
    </div>
  )
}
