import { toast } from 'react-toastify'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostTranslationRequest } from '@biz/api'

type ParameterType = { id: number }
type FunctionType = () => UseMutationResult<unknown, unknown, ParameterType, unknown>
export const useTranslateMutation: FunctionType = () =>
  useMutation({
    mutationFn: ({ id }) =>
      fetchPostTranslationRequest(id).then(
        () => {
          toast('번역을 시작했습니다.')
        },
        (err) => {
          const status = Math.round((err?.response?.status || 1) / 100)
          if (status === 4 || status === 5) {
            toast.error(`에러가 발생했습니다. 잠시후 다시 시도해주세요. - ${err?.response?.status}`)
          }
        }
      ),
  })
