import { useQuery, type QueryClient } from '@tanstack/react-query'
import { fetchCountriesInfo } from '@biz/api'

const queryKey = ['@libs/biz-center/wadizGlobal/CountriesInfo']
export const useCountriesInfoQuery = () =>
  useQuery({
    queryKey,
    queryFn: () =>
      fetchCountriesInfo().then((res) => {
        const countries = res?.data?.countries || []
        return {
          countries,
          countryGroups: res?.data?.countryGroups || [],
          codeMap: new Map(countries.map((n) => [n.countryCode, n])),
        }
      }),
    refetchOnReconnect: true,
  })

type CountriesInfoCache = ReturnType<typeof useCountriesInfoQuery>['data']
export const loadCountriesInfoCache = (queryClient: QueryClient) => () =>
  queryClient.getQueryData<CountriesInfoCache>(queryKey)
