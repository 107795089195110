import { useState, useCallback } from 'react'
import { Card, Tag, Form, Button } from 'antd'
import { useCreativeDataQuery, useCampaignInfoQuery, useCampaignStatus } from '@biz/query'
import alertify from 'alertifyjs'
import { targetFormRegistry, FacebookFrame } from '@biz/ui'
import { useFacebookLink } from './_hooks'
import { ContextMenu } from './ContextMenu'
import { InputAdCopy } from './InputAdCopy'
import { ImageManager } from './ImageManager'
import { CTASelector } from './CTASelector'
import { InputAdTitle } from './InputAdTitle'
import { AssetStatusBadge } from './AssetStatusBadge'
import { InputAdDescription } from './InputAdDescription'
import { ReinspectionButton } from './ReinspectionButton'
import { TranslateButton } from './TranslateButton'
import { ContentPreview } from './ContentPreview'
import { CreationInspectionHistoryModal } from '../../../../../views/mngAd/creation/CreationInspectionHistoryModal'
import { EditModeConfirmModal } from './EditModeConfirmModal'
import { ContentInspection } from './ContentInspection'
import { TranslateCover, useTranslateStart } from './TranslateCover'
import { useTranslationStatusQuery } from '@biz/query'
import { PreviewModal } from './PreviewModal'
import styles from './CreativeAssetsCollector.module.scss'

const { CreativeAssetsId, CreativeAssetsName } = targetFormRegistry

type CreativeAssetsCollectorFunction = {
  name: number // Form.List가 주는 name
  index: number
  length: number
  onRemove?: () => void
  onDuplicate?: () => void
  onContentUpdate?: () => void
}
export const CreativeAssetsCollector: React.FC<CreativeAssetsCollectorFunction> = ({
  name,
  index,
  length,
  onRemove,
  onDuplicate,
  onContentUpdate,
}) => {
  const { isFinished, isBeforeInspection, inOperation } = useCampaignStatus()
  const { data: creativeData, update: updateCreativeData } = useCreativeDataQuery()
  const { data: campaignInfo } = useCampaignInfoQuery()
  const isGlobal = campaignInfo?.data?.isGlobal || false

  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  const {
    creationViewStatus,
    id: creationDataId,
    wabizInspConfirmTime,
    syncLastTime,
    wabizInspStatus,
    editStatus,
    afList,
  } = creativeData?.[index] || {}

  // 와디즈에서 컨펌하고 메타에서 컨펌하기 전
  const isBetweenWadizConfirmToMeta =
    (!wabizInspConfirmTime || !syncLastTime || syncLastTime < wabizInspConfirmTime) && wabizInspStatus === 'ok'

  // 상태값 (이렇게 복잡할 필요가 있을까 싶네... )
  const isOpenStatusBadge = !!creationViewStatus && inOperation?.()
  const isOpenInspectionHistoryButton = inOperation?.()
  const isOpenReInspectionButton =
    !!creationViewStatus && ((inOperation?.() && !isBetweenWadizConfirmToMeta) || editStatus === 'editing')
  const isOpenContextMenu =
    !isFinished?.() && (isBeforeInspection?.() || inOperation?.()) && !isBetweenWadizConfirmToMeta

  const isNoEditable =
    wabizInspConfirmTime !== null && wabizInspConfirmTime !== undefined && isBetweenWadizConfirmToMeta
  const isOpenTranslateButton = !isNoEditable

  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false)

  const facebookLink = useFacebookLink(creationDataId)

  const [isPreview, setPreview] = useState(() => editStatus === 'editing')
  const doBodyClick = useCallback(
    (e: React.MouseEvent) => {
      if (isNoEditable && isPreview) {
        alertify.alert(
          '소재 수정 불가',
          '승인된 소재 정보를 광고 매체에 전달 중입니다.<br><br>정상적으로 소재 정보 전달이 완료 되기 전까지 소재 정보 수정이 불가 합니다. <br><br>정보 전송은 약 10분 정도 진행 되오니 이후 다시 시도해 주세요.<br>'
        )
        return
      }
      if (!isNoEditable && !!wabizInspConfirmTime && isPreview) {
        setOpenConfirmModal(true)
      } else {
        setPreview(!isPreview)
      }
    },
    [isNoEditable, wabizInspConfirmTime, isPreview]
  )
  const [isInInspection, setInInspection] = useState(false)

  const translate = useTranslateStart()
  const { data } = useTranslationStatusQuery({ id: creationDataId || 0 })
  const { translateStatus } = data || {}
  const inTranslate = translateStatus !== undefined && translateStatus !== 'APPLIED'
  const [isOpenPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
  return (
    <>
      <Card
        className={`${styles.containCreativeAssetsCollector} ${editStatus === 'editing' ? styles.editMode : ''}`}
        style={{
          cursor: isNoEditable ? 'default' : 'pointer',
        }}
        hoverable
        extra={
          <>
            <Tag className={styles.tag}>{`${index + 1}/${length}`}</Tag>
            <div className={isBeforeInspection?.() ? styles.btnRightGroup : styles.btnGroup}>
              {isOpenStatusBadge && <AssetStatusBadge status={creationViewStatus} creationDataId={creationDataId} />}
              {isOpenInspectionHistoryButton && (
                <Button
                  size="small"
                  className={styles.historyButton}
                  onClick={() => setIsOpenHistoryModal(true)}
                  disabled={!creativeData?.[index]}
                >
                  검수 이력
                </Button>
              )}
              {isOpenReInspectionButton && (
                <ReinspectionButton
                  disabled={!afList || afList?.length <= 0}
                  status={creationViewStatus}
                  onInspection={() => setInInspection(true)}
                />
              )}
              {isGlobal && (
                <Form.Item dependencies={[]} style={{ padding: 0, margin: 0 }}>
                  {() => {
                    if (!isOpenTranslateButton && !isBeforeInspection?.()) {
                      return null
                    }
                    return (
                      !!creationDataId && (
                        <TranslateButton
                          onTranslate={() => translate(creationDataId)}
                          creationDataId={creationDataId}
                        />
                      )
                    )
                  }}
                </Form.Item>
              )}
            </div>
            {isOpenContextMenu && (
              <Form.Item name={[name, CreativeAssetsId]} style={{ marginRight: 0 }}>
                <ContextMenu
                  onDelete={onRemove}
                  onCopy={onDuplicate}
                  onEdit={() => {
                    if (translateStatus !== undefined) {
                      // 번역중
                      setOpenPreviewModal(true)
                    } else if (!isNoEditable && !!wabizInspConfirmTime && isPreview) {
                      setOpenConfirmModal(true)
                    } else {
                      setPreview(!isPreview)
                    }
                  }}
                  editCaption={isPreview ? '편집' : '미리보기'}
                  onGotoFacebook={facebookLink}
                  disabled={!creativeData?.[index]}
                  isInTranslation={translateStatus !== undefined}
                  onTranslate={isBeforeInspection?.() ? undefined : () => translate(creationDataId)}
                />
              </Form.Item>
            )}
          </>
        }
      >
        {!isPreview && !isFinished?.() && !isBetweenWadizConfirmToMeta ? (
          <FacebookFrame onClick={doBodyClick}>
            <InputAdCopy containerName={name} onUpdate={onContentUpdate} disabled={!creativeData?.[index]} />
            <ImageManager containerName={name} disabled={!creativeData?.[index]} />
            <CTASelector containerName={name} onUpdate={onContentUpdate} disabled={!creativeData?.[index]} />
            <InputAdTitle containerName={name} onUpdate={onContentUpdate} disabled={!creativeData?.[index]} />
            <InputAdDescription containerName={name} onUpdate={onContentUpdate} disabled={!creativeData?.[index]} />
          </FacebookFrame>
        ) : (
          <FacebookFrame isPreview onClick={doBodyClick}>
            <ContentPreview containerName={name} />
          </FacebookFrame>
        )}
        {isGlobal && !!creationDataId && (
          <TranslateCover
            creationDataId={creationDataId}
            onTranslate={() => translate(creationDataId)}
            onPreview={() => setOpenPreviewModal(true)}
          />
        )}
      </Card>
      <PreviewModal isOpen={isOpenPreviewModal} onClose={() => setOpenPreviewModal(false)}>
        <FacebookFrame isPreview onClick={doBodyClick}>
          <ContentPreview containerName={name} />
        </FacebookFrame>
      </PreviewModal>
      {creativeData?.[index] && (
        <CreationInspectionHistoryModal
          isOpen={isOpenHistoryModal}
          searchType="maker"
          creationDataId={creativeData[index].id}
          projectId={campaignInfo?.data?.projectId}
          setShowCreationDataInspHisDialog={() => setIsOpenHistoryModal(false)}
        />
      )}
      <EditModeConfirmModal
        isOpen={isOpenConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={() => {
          setPreview(false)
          setOpenConfirmModal(false)
        }}
      />
      <ContentInspection
        isOpen={isInInspection}
        creationDataId={creationDataId || 0}
        onClose={() => {
          setInInspection(false)
          updateCreativeData()
        }}
      />
    </>
  )
}
