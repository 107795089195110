import { useState } from 'react'
import { Button } from 'antd'
import { ReactComponent as CheckIcon } from '@wadiz-frontend/waffle-system-icons/check.svg'
import { ReactComponent as ChevronDown } from '@wadiz-frontend/waffle-system-icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@wadiz-frontend/waffle-system-icons/chevron-up.svg'
import { ReactComponent as ErrorIcon } from '@wadiz-frontend/waffle-system-icons/error.svg'
import { ChinaAdRestrictionInfo } from './ChinaAdRestrictionInfo'
import styles from './SelectedNationList.module.scss'

type SelectedNationListFunction = {
  isPending?: boolean
  list?: string[]
}
export const SelectedNationList: React.FC<SelectedNationListFunction> = ({ list }) => {
  const [isExpanse, setExpanse] = useState<boolean>(false)
  return (
    <section className={styles.containSelectedNationList}>
      {!list || list.length < 1 ? (
        <div className={styles.emptyBox}>
          <ErrorIcon />
          <div>선택된 타겟 국가가 없어요.</div>
          <div>캠페인을 진행할 국가를 선택해 주세요.</div>
        </div>
      ) : (
        <div className={styles.tagList} style={{ maxHeight: isExpanse ? 'fit-content' : 300 }}>
          <div className={styles.selectedCount}>
            <CheckIcon /> {`${list.length}개 국가 선택됨`}
          </div>
          <div className={styles.tags}>
            {list.map((n) => (
              <div key={n}>{n}</div>
            ))}
          </div>
          {list.length > 50 &&
            (!isExpanse ? (
              <div className={styles.extendButtonWrapper}>
                <div className={styles.whiteGradient} />
                <Button block icon={<ChevronDown />} iconPosition="end" onClick={() => setExpanse(true)}>
                  {'전체보기'}
                </Button>
              </div>
            ) : (
              <div className={styles.collapseButtonWrapper}>
                <Button block icon={<ChevronUp />} iconPosition="end" onClick={() => setExpanse(false)}>
                  {'접기'}
                </Button>
              </div>
            ))}
        </div>
      )}
      <ChinaAdRestrictionInfo />
    </section>
  )
}
