import { Modal, Button } from 'antd'
import { useCampaignPayInfoQuery, useRemainAmountQuery } from '@biz/query'
import { StarComment } from '../StarComment'
import { PaymentInfo } from './PaymentInfo'
import { PaymentConfirmInfo } from './PaymentConfirmInfo'

import styles from './PaymentSummaryModal.module.scss'

type PaymentSummaryModalFunction = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
  payType: 'POST_PAYMENT' | 'BIZ_MONEY'
}
export const PaymentSummaryModal: React.FC<PaymentSummaryModalFunction> = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  payType,
}) => {
  const { data: payTypeCampaignInfo } = useCampaignPayInfoQuery(isOpen, payType)
  const paymentInfo = payTypeCampaignInfo?.data
  const { data: remainAmountInfo } = useRemainAmountQuery(isOpen, payType)
  const { currentCost } = remainAmountInfo || {}
  return (
    <Modal
      width={900}
      open={isOpen}
      closable={false}
      centered
      title={
        <div className={styles.modalTitle}>
          <h3>{'와디즈에 광고 진행을 요청합니다.'}</h3>
          <StarComment>{'소재 검수를 통과하면 캠페인 시작일부터 SNS 매체에 광고를 노출합니다.'}</StarComment>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} style={{ width: 200 }} loading={isLoading}>
            {'확인'}
          </Button>
        </div>,
      ]}
      className={styles.containPaymentSummaryModal}
    >
      <div className={styles.customBody}>
        <PaymentInfo info={paymentInfo} payType={payType} />
        <PaymentConfirmInfo info={paymentInfo} payType={payType} currentCost={currentCost} />
      </div>
    </Modal>
  )
}
