import { useState } from 'react'
import type { CountryType } from '@biz/api'
import { NationSearch } from '../NationSearch'
import { EmptyReason } from '../EmptyReason'
import { CountryFlatList } from '../CountryFlatList'
import styles from './SupportCountryList.module.scss'

type SupportCountryListFunction = {
  supportedCountries?: CountryType[]
  value?: string[]
  onChange?: (v: string[]) => void
}
export const SupportCountryList: React.FC<SupportCountryListFunction> = ({ supportedCountries, value, onChange }) => {
  const [searchKeyword, setKeyword] = useState<string>('')
  const list =
    (searchKeyword !== ''
      ? supportedCountries?.filter((country) => country.name.indexOf(searchKeyword) > -1)
      : supportedCountries) || []
  const activeSet = new Set(list.map((n) => n.countryCode))

  return (
    <div className={styles.containSupportCountryList}>
      {supportedCountries && supportedCountries?.length < 1 ? (
        <EmptyReason
          reason={
            <>
              <div>프로젝트에서 설정한 참여 가능 국가가 없어요.</div>
              <div>‘전체 국가’ 탭에서 국가를 선택해 주세요.</div>
            </>
          }
        />
      ) : (
        <>
          <NationSearch value={searchKeyword} onChange={setKeyword} />
          <CountryFlatList
            options={list}
            value={(value || []).filter((code) => activeSet.has(code))}
            onChange={(v) => onChange?.([...v, ...(value || []).filter((item) => !activeSet.has(item))])}
          />
        </>
      )}
    </div>
  )
}
