import { useState } from 'react'
import type { CountryType, CountryGroupType } from '@biz/api'
import { NationSearch } from '../NationSearch'
import { CountMessageBox } from '../CountMessageBox'
import { CountryFlatList } from '../CountryFlatList'
import { CountryList } from './CountryList'

import styles from './WholeCountryList.module.scss'

type WholeCountryListFunction = {
  value?: string[]
  onChange?: (v: string[]) => void
  countries: CountryType[]
  countryGroups: CountryGroupType[]
  supportedCountries?: CountryType[]
  codeMap: Map<string, CountryType>
}
export const WholeCountryList: React.FC<WholeCountryListFunction> = (props) => {
  const { value, onChange, countries } = props
  const [searchKeyword, setKeyword] = useState<string>('')
  const list =
    (searchKeyword !== '' ? countries?.filter((country) => country.name.indexOf(searchKeyword) > -1) : countries) || []
  const activeSet = new Set(list.map((n) => n.countryCode))

  return (
    <div className={styles.containWholeCountryList}>
      <NationSearch value={searchKeyword} onChange={setKeyword} />
      {searchKeyword !== '' ? (
        <CountryFlatList
          options={list}
          value={(value || []).filter((code) => activeSet.has(code))}
          onChange={(v) => onChange?.([...v, ...(value || []).filter((item) => !activeSet.has(item))])}
        />
      ) : (
        <CountryList {...props} />
      )}
    </div>
  )
}
