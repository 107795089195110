import { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import { LoadingBar } from '@common/ui'
import { CountriesTab } from './CountriesTab'
import { useCountriesInfoQuery } from '@biz/query'

import styles from './NationSelectModal.module.scss'

type NationSelectModalFunction = {
  init?: string[] // code
  isOpen: boolean
  onClose: () => void
  shippingCountries?: string
  onResult: (v: string[]) => void
}
export const NationSelectModal: React.FC<NationSelectModalFunction> = ({
  init,
  isOpen,
  onClose,
  onResult,
  shippingCountries,
}) => {
  const { data, isPending } = useCountriesInfoQuery()
  const { countries, countryGroups, codeMap } = data || {
    countries: [],
    countryGroups: [],
    codeMap: new Map(),
  }
  const supportedCountries = (shippingCountries || '')
    .split(',')
    .filter((n) => n.trim().toUpperCase() !== 'KR')
    .map((c) => codeMap?.get(c.trim().toUpperCase()))

  const [selected, setSelected] = useState<string[]>()
  useEffect(() => {
    setSelected(init)
  }, [init])
  return (
    <Modal
      title="타겟 국가 선택"
      width={600}
      open={isOpen}
      closable={false}
      centered
      className={styles.containNationSelectModal}
      classNames={{
        content: styles.content,
        body: styles.body,
        header: styles.header,
        footer: styles.footer,
      }}
      footer={[
        <Button type="default" block size="large" onClick={onClose} key={'취소'}>
          {'취소'}
        </Button>,
        <Button
          type="primary"
          block
          size="large"
          disabled={(selected || []).length < 1}
          onClick={() => onResult(selected || [])}
          key={'저장'}
        >
          {'저장'}
        </Button>,
      ]}
    >
      {isPending ? (
        <div style={{ padding: 16 }}>
          <LoadingBar />
        </div>
      ) : (
        <CountriesTab
          value={selected}
          onChange={setSelected}
          countries={countries}
          countryGroups={countryGroups}
          supportedCountries={supportedCountries}
          codeMap={codeMap}
        />
      )}
    </Modal>
  )
}
