import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Form, FormInstance } from 'antd'
import type { GeoInfoType } from '@biz/api' // source type
import {
  useCampaignAppendInfoMutation,
  loadAdditionalCampaignInfo,
  loadCachedLocationList,
  loadCountriesInfoCache,
} from '@biz/query'
import { targetFormRegistry } from '@biz/ui'

const { AgeRangePickerName, GenderSelectorName, LocationSelectorName, CountriesSelectorName } = targetFormRegistry
export const useUpdateCampaign = (formInstance?: FormInstance) => {
  const queryClient = useQueryClient()

  const form = formInstance || Form.useFormInstance()
  const updateCampaign = useCampaignAppendInfoMutation()

  return useCallback(async () => {
    const v = form.getFieldsValue()
    // 국내
    const locationList = loadCachedLocationList(queryClient)()
    const location = ((v[LocationSelectorName] || []) as string[])
      .map((name) => {
        const foundLocationData = (locationList || []).find((n) => n?.name === name)
        return foundLocationData
      })
      .filter((n) => !!n) as GeoInfoType[]

    //국외
    const { codeMap } = loadCountriesInfoCache(queryClient)() || {}
    const selectedCountries = (v[CountriesSelectorName] || []) as string[]
    const countryList = !codeMap ? [] : selectedCountries.map((code) => codeMap.get(code))

    //연령
    const vAge = v[AgeRangePickerName] || []
    const maxAge = `${vAge[1]}${vAge[1] === 65 ? '+' : ''}`
    const minAge = `${vAge[0]}`

    //성별
    const genderDiv = v[GenderSelectorName] || []

    const campaignData = await loadAdditionalCampaignInfo(queryClient)()
    const { adSetExtYn, id: adSetId, campId } = campaignData || {}
    return updateCampaign.mutateAsync({
      location: (location.length > 0
        ? location
        : countryList.length > 0
        ? countryList
        : []) as unknown as GeoInfoType[],
      maxAge,
      minAge,
      genderDiv,
      adSetExtYn,
      adSetId,
      campId,
    })
  }, [updateCampaign, form])
}
