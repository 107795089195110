import styles from './GlobalCampaignTip.module.scss'

export const GlobalCampaignTip = () => (
  <section className={styles.containGlobalCampaignTip}>
    <h5>{'글로벌 캠페인은 소재를 영어로 제작했을 때 광고 효율이 좋아요.'}</h5>
    <pre>{`영문 소재 제작이 어렵다면, AI 영문 번역 기능을 활용해 보세요.
(AI 영문 번역은 텍스트, 이미지 소재에만 사용 가능해요.)`}</pre>
  </section>
)

