import { Button } from 'antd'
import { ReactComponent as ErrorIcon } from '@wadiz-frontend/waffle-artworks/error.svg'
import styles from './TranslateCover.module.scss'


type TranslateCoverFunction = {
  onTranslate: () => void
  onStopTranslate: () => void
}
export const TranslateCoverError: React.FC<TranslateCoverFunction> = ({ onTranslate, onStopTranslate }) => (
  <div className={styles.containTranslateCover}>
    <ErrorIcon />
    <div>
      <h5>{'AI 번역에 실패했어요.'}</h5>
      <div className={styles.notify}>{`일시적인 오류로 번역에 실패했어요\n번역을 재요청해 주세요.`}</div>
    </div>
    <div className={styles.buttonContainer}>
      <Button type="text" danger onClick={onStopTranslate}>
        {'번역 취소하기'}
      </Button>
      <Button type="primary" onClick={onTranslate}>
        {'번역 재요청하기'}
      </Button>
    </div>
  </div>
)

