import { Modal, Button } from 'antd'
import styles from './ConfirmModal.module.scss'

type ConfirmModalFunction = {
  isOpen: boolean
  onCancel?: () => void
  onConfirm?: () => void
  title?: React.ReactNode
  children?: React.ReactNode
  cancel?: string
  confirm?: string
}
export const ConfirmModal: React.FC<ConfirmModalFunction> = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  children,
  cancel,
  confirm,
}) => (
  <Modal
    width={400}
    open={isOpen}
    onCancel={onCancel}
    title={title}
    footer={[
      <Button type="default" size="large" onClick={onCancel}>
        {cancel}
      </Button>,
      <Button type="primary" size="large" onClick={onConfirm}>
        {confirm}
      </Button>,
    ]}
    className={styles.containConfirmModal}
    classNames={{
      content: styles.content,
      body: styles.body,
      header: styles.header,
      footer: styles.footer,
    }}
  >
    {children}
  </Modal>
)
