import { useState } from 'react'
import { Checkbox, type CheckboxProps } from 'antd'
import { ReactComponent as InfoIcon } from '@wadiz-frontend/waffle-system-icons/info.svg'
import type { TranslateResultInfo } from '@biz/api'
import styles from './MediaDescriptor.module.scss'

type MediaDescriptorFunction = {
  orgUrl?: string
  item?: TranslateResultInfo
  value: boolean
  onChange: (v: boolean) => void
}
export const MediaDescriptor: React.FC<MediaDescriptorFunction> = ({ orgUrl, item, value, onChange }) => (
  <div className={styles.containMediaDescriptor}>
    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
    <img src={value ? item?.imageUrl : orgUrl} />
    {item?.comment && (
      <div className={styles.notify}>
        <InfoIcon />
        {item.comment}
      </div>
    )}
  </div>
)
