import { ReactComponent as ErrorIcon } from '@wadiz-frontend/waffle-system-icons/error.svg'
import styles from './EmptyReason.module.scss'

type EmptyReasonFunction = {
  reason: React.ReactNode
}
export const EmptyReason: React.FC<EmptyReasonFunction> = ({ reason }) => (
  <div className={styles.containEmptyReason}>
    <ErrorIcon />
    {reason}
  </div>
)

