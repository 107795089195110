import { axios } from '../axios'
// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732913716/-+API#%EB%B2%88%EC%97%AD-%EA%B2%B0%EA%B3%BC-%EC%A1%B0%ED%9A%8C
export type TranslateBeforeInfo = {
  originAfId: number // 원본 소재파일 아이디
  imageUrl: string // 원본 이미지 URL
  afMetaDto: {
    fileDev: string // IMG / VIDEO
    ext: string //  파일확장자
    width: number // 이미지 가로크기
    height: number // 이미지 세로크기
  }
}
export type TranslateResultInfo = {
  translateAfId: number // 번역 후 소재파일 아이디 long 번역결과 소재파일 아이디
  originAfId: number // long 원본 소재파일 아이디
  imageUrl: string //string 번역 후 이미지 URL
  afMetaDto: {
    fileDev: string // IMG / VIDEO
    ext: string // 파일확장자
    width: number // 이미지 가로크기
    height: number // 이미지 세로크기
  }
  result: string // string 번역 결과 success / fail
  comment: string // string 번역 결과에 대한 코멘트
}
type ResponseType = {
  ok: boolean
  data: {
    translateResultId: number // 텍스트번역 결과 아이디
    originAdTitle01: string //  string 번역 전 광고 제목1
    transAdTitle01: string //  string 번역 후 광고 제목1
    originAdTitle02: string //  string 번역 전 광고 제목2
    transAdTitle02: string //  string 번역 후 광고 제목2
    originAdDesc: string //  string 번역 전 광고 문구
    transAdDesc: string //  string 번역 후 광고 문구
    originAfList: TranslateBeforeInfo[]
    transAfList: TranslateResultInfo[] //번역 후 소재 이미지 리스트
  }
}
export const fetchTranslationResult = (creationDataId: number): Promise<ResponseType> =>
  axios.get(`/api/camp/make02/translate/${creationDataId}`)
