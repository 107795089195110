import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import { TooltipHelp } from './TooltipHelp'
import styles from './CampaignCaption.module.scss'

type CampaignCaptionFunction = {
  children: React.ReactNode
  classSet?: { container?: string; title?: string }
  isSub?: boolean
  tooltip?: React.ReactNode
  appendComponent?: React.ReactNode
  required?: boolean
}
export const CampaignCaption: React.FC<CampaignCaptionFunction> = ({
  children,
  classSet,
  isSub,
  tooltip,
  appendComponent,
  required = true,
}) => {
  return (
    <dt
      className={
        classSet?.container
          ? classSet?.container
          : isSub
          ? styles.containCampaignSubCaption
          : styles.containCampaignCaption
      }
    >
      <div className={classSet?.title ? classSet?.title : isSub ? styles.subTitle : styles.title}>
        {children}
        {tooltip && <TooltipHelp tooltip={tooltip} />}
        {required && <StartIcon />}
      </div>
      {appendComponent}
    </dt>
  )
}
