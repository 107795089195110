import { axios } from '../axios'
import type { CampaignStatusType } from './campaignStatus'
// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16487514113/-+API#%F0%9F%9A%A7-%EC%BA%A0%ED%8E%98%EC%9D%B8-%EC%A0%95%EB%B3%B4-%EC%A1%B0%ED%9A%8C
export type CampaignInfoType = {
  abtEndYn: boolean // true
  abtStartDate: string //'20240912'
  abtUseYn: boolean //false
  accActYn: boolean //true
  actYn: boolean //true
  adminCampYn: boolean //false
  campBudgetTotal1: number //300000
  campDepBudget: number //0
  campDepBuff: number //0
  campDepFees: number //0
  campDepVat: number //0
  campEndTime: string //'202409141128'
  campGoalDiv: string //'CONVERSIONS'
  campHashNo: string //'20'
  campInspStartYn: boolean //false
  campNm: string //'임시_202409121126'
  campStartTime: string //'202409121128'
  campViewStatus: CampaignStatusType //'temp'
  creationDataCnt: number //1
  fixedBasicFees: number //1000
  id: number //238179033
  mediaDiv4: string // 'FBITG'
  payType: 'BIZ_MONEY' | 'POST_PAYMENT'
  projectFundingDiv: string //'NON'
  projectId: string
  snsActYn: boolean //true
  snsApiOkYn: boolean //true
  syncDirtyYn: boolean //false
  syncYn: boolean //false
  useYn: boolean //true
  isGlobal: boolean // 글로벌 캠페인 여부 ( true: 글로벌 캠페인,  false: 국내 캠페인)
}

type ReturnType = {
  ok: boolean
  data: CampaignInfoType
}

type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchCampaignInfo: FunctionType = (campId) => axios.get(`/api/camp/make02/getCampInfo?campId=${campId}`)
