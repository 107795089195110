import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import isEqual from 'lodash/isEqual'
import { fetchTranslationStatusAll, TranslationInfo } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

const statusFilter = ['PROCESSING', 'COMPLETED', 'CANCELED', 'APPLIED']
const queryKey = ['@apps/biz/TargetCreatePage/CreativeAssetsContainer/TranslateCover/TranslationStatus']
const useTranslationStatusAllQuery = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()

  const queryClient = useQueryClient()
  const isSubscribe = Boolean(queryClient.getQueryData([...queryKey, 'subscribe']))
  if (!isSubscribe) {
    queryClient.setQueryData([...queryKey, 'subscribe'], true)
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      const { type, query } = event || {}
      const isChangedTranslationStatus = query.queryKey[0] === queryKey[0]

      const isRootQuery = query.queryKey.length === 1
      if (type === 'removed' && isChangedTranslationStatus && isRootQuery) {
        console.log('unsubscribe')
        unsubscribe()
        return
      }

      if (type === 'updated' && isRootQuery && isChangedTranslationStatus) {
        const transSet = queryClient.getQueryData<{ [k: string]: TranslationInfo }>(queryKey)
        Object.keys(transSet || {}).forEach((id) => {
          const subKey = [...queryKey, Number(id)]
          const newData = transSet?.[id]
          const oldData = queryClient.getQueryData(subKey)
          if (!isEqual(newData, oldData)) {
            queryClient.setQueryData(subKey, newData)
          }
        })
      }
    })
  }
  const isPolling =
    queryClient.getQueryData([...queryKey, 'status']) ??
    (queryClient.setQueryData([...queryKey, 'status'], true) || true)

  return useQuery({
    queryKey,
    queryFn: () => {
      if (!campaignId) {
        return Promise.reject()
      }
      const previousData = queryClient.getQueryData<Record<string, TranslationInfo>>(queryKey)

      return fetchTranslationStatusAll(campaignId).then(
        (res) => {
          if (res?.ok !== true) {
            return Promise.reject()
          }
          const list = res.data as TranslationInfo[]
          const processList = (list || []).filter((item) => statusFilter.includes(item.translateStatus))
          const newData = Object.fromEntries(processList.map((item) => [item.creationDataId, item]))
          if (previousData && isEqual(previousData, newData)) {
            return previousData
          }
          if (processList.length < 1) {
            queryClient.setQueryData([...queryKey, 'status'], false)
          }
          return newData
        },
        (err) => {
          const status = Math.round((err?.response?.status || 1) / 100)
          if (status === 4 || status === 5) {
            toast.error(`에러가 발생했습니다. 잠시후 다시 시도해주세요. - ${err?.response?.status}`)
          }
        }
      )
    },
    refetchInterval: !isPolling ? false : 10 * 1000, //10초마다 갱신
    enabled: Boolean(campaignId),
  })
}

export const useTranslationStatusQuery = ({ id }: { id: number }) => {
  useTranslationStatusAllQuery()
  return useQuery<TranslationInfo>({ queryKey: [...queryKey, id] })
}

export const refetchTranslationStatus = () => {
  const queryClient = useQueryClient()
  return useCallback(() => queryClient.invalidateQueries({ queryKey }), [])
}

export const setPendingToTranslationStatus = () => {
  const queryClient = useQueryClient()
  return useCallback(
    ({ id }: { id: number }) =>
      queryClient.setQueryData([...queryKey, id], {
        creationDataId: 0,
        translateReqId: 0,
        translateStatus: 'PENDING',
        translateStatusDesc: '준비중',
      }),
    []
  )
}
