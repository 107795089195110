import React from 'react'
import cx from 'classnames'
import { ChevronDownIcon } from '@wadiz-frontend/waffle-icons-modified'
import styles from './Select.module.scss'

export interface OptionType {
  label: string
  value: string | number
}

export interface SelectProps<T = HTMLSelectElement> extends Omit<React.SelectHTMLAttributes<T>, 'size'> {
  label?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  status?: 'error'
  name?: string
  value?: string | ReadonlyArray<string> | number
  placeholder?: string
  options?: ReadonlyArray<OptionType>
  disabled?: boolean
  inputRef?: (ref: HTMLSelectElement | null) => void
}

export const Select = ({
  label,
  size = 'lg',
  status,
  name,
  value,
  placeholder,
  options = [],
  disabled,
  onChange,
  onClick = () => {},
  className,
  inputRef = () => {},
  ...props
}: SelectProps) => {
  const selectedOptions = options.filter((option: any) => option.value === value && option.label)
  const selectedOption = selectedOptions[0]

  return (
    <div
      className={cx(
        styles.select,
        disabled,
        size && {
          [styles.sm]: size === 'sm',
          [styles.md]: size === 'md',
          [styles.lg]: size === 'lg',
          [styles.xl]: size === 'xl',
        },
        {
          [styles.error]: status === 'error',
        },
        {
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <div className={cx(styles.value, { [styles.placeholder]: !value && value !== 0 })} aria-hidden>
        {(selectedOption && selectedOption.label) || placeholder}
        <ChevronDownIcon size={size === 'sm' ? 16 : size === 'md' ? 20 : 24} inline />
      </div>
      <select {...props} name={name} value={value || ''} ref={(ref) => inputRef(ref)} onChange={onChange}>
        <option value="">{placeholder}</option>
        {options &&
          options.map((option: any, idx: number) => (
            <option key={`${option.value}_${idx}`} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  )
}

export default React.memo(Select)
