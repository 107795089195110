import { axios } from '../axios'
import { TranslationStatusType } from './TranslationStatusType'
// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732913716/-+API#%EB%B2%88%EC%97%AD-%EC%83%81%ED%83%9C-%EC%A1%B0%ED%9A%8C(%EC%BA%A0%ED%8E%98%EC%9D%B8-%EC%A0%84%EC%B2%B4)

export type TranslationInfo = {
  creationDataId: number //  long 소재 아이디
  translateReqId: number // long 번역요청 아이디
  translateStatus: TranslationStatusType // String 번역 상태 REQUESTED PROCESSING COMPLETED CANCELED
  translateStatusDesc: string // String 요청됨,번역중,번역완료,취소
}
type ResponseType = {
  ok: boolean
  data: TranslationInfo[]
}
export const fetchTranslationStatusAll = (campaignId: number): Promise<ResponseType> =>
  axios.get(`/api/camp/make02/translate/status/${campaignId}`)
