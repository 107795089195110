import { Collapse, Checkbox, type CollapseProps } from 'antd'
import { ReactComponent as ChevronUpIcon } from '@wadiz-frontend/waffle-system-icons/chevron-up.svg'
import type { CountryType } from '@biz/api'
import styles from './CountryGroupCollapse.module.scss'

export type CountryGroupCollapseData = {
  key: string
  name: string
  caption?: string
  options: CountryType[]
}
type CountryGroupCollapseFunction = {
  list: CountryGroupCollapseData[]
  recommendSet: Set<string>
  listSet: { [index: string]: Set<string> }
  value?: string[]
  onChange?: (v: string[]) => void
}
export const CountryGroupCollapse: React.FC<CountryGroupCollapseFunction> = ({
  list,
  listSet,
  recommendSet,
  value,
  onChange,
}) => {
  const guardValue = value || []
  const values: Record<string, string[]> = list.reduce((acc, n) => {
    acc[n.key] = guardValue.filter((code) => listSet[n.key].has(code))
    return acc
  }, {} as Record<string, string[]>)

  const items: CollapseProps['items'] = (list || []).map(({ key, name, caption, options }) => ({
    key,
    label: (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          indeterminate={values[key].length > 0 && values[key].length < options.length}
          checked={values[key].length > 0 && values[key].length === options.length}
          onChange={(e) => {
            if (e?.target?.checked === true) {
              onChange?.([...new Set([...guardValue, ...options.map((n) => n.countryCode)])])
            } else {
              onChange?.(guardValue.filter((n) => !listSet[key].has(n)))
            }
          }}
        >
          {name}
          {caption && <span className={styles.caption}>{caption}</span>}
        </Checkbox>
      </div>
    ),
    children: (
      <Checkbox.Group
        className={styles.checkboxGroup}
        value={value}
        onChange={(checked) =>
          onChange?.([...new Set([...guardValue.filter((n) => !listSet[key].has(n)), ...checked])])
        }
        options={options.map((n) => ({
          value: n.countryCode,
          label: (
            <div className={styles.countryItem}>
              {n.countryName}
              {recommendSet.has(n.countryCode) ? <div className={styles.recommendItem}>추천 국가</div> : null}
            </div>
          ),
        }))}
      />
    ),
  }))
  return items.length < 1 ? null : (
    <Collapse
      items={items}
      rootClassName={styles.collapse}
      className={styles.containCountryGroupCollapse}
      accordion
      expandIconPosition="end"
      expandIcon={({ isActive }) => <ChevronUpIcon className={isActive ? '' : styles.expandMoreReverseIcon} />}
    />
  )
}
