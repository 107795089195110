import { Modal, Button } from 'antd'
import { thousandsGrouping } from '@common/utils'
import { ReactComponent as InfoIcon } from '@common/svg/info.svg'
import styles from './BudgetUpdateConfirmModal.module.scss'

export type BudgetUpdateConfirmInfoData = {
  difference: number
  confirmCondition: 1 | 2 | 3
  beforeCampBudgetTotal: number
  afterCampBudgetTotal: number
  cost: number
}
type BudgetUpdateConfirmModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  info: BudgetUpdateConfirmInfoData | null
}
export const BudgetUpdateConfirmModal: React.FC<BudgetUpdateConfirmModalFunction> = ({
  isOpen,
  onClose,
  onConfirm,
  info,
}) =>
  !!info && (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <div>캠페인 예산을 수정하시겠어요?</div>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm}>
            {'수정'}
          </Button>
        </div>,
      ]}
      className={styles.containBudgetUpdateConfirmModal}
    >
      <div className={styles.customBody}>
        <div className={styles.subList}>
          <div>기존 예산</div>
          <div>{thousandsGrouping(info.beforeCampBudgetTotal || 0)}원</div>
        </div>
        <div className={styles.subList}>
          <div>변경 예산</div>
          <div>
            <strong>{thousandsGrouping(info.afterCampBudgetTotal || 0)}원</strong>
          </div>
        </div>
        {info.confirmCondition === 1 && (
          <div className={styles.grayBox}>
            <ul className={styles.contentList}>
              <li>
                매체와의 연동 딜레이로 인해 일일 예산이 조정될 수 있습니다.(실제 소진된 비용이 변경한 예산보다 많은
                경우)
              </li>
              <li>예산 변경 후 발생한 잔여 예치금은 캠페인 종료일로부터 4일 후 반환됩니다.</li>
            </ul>
          </div>
        )}
        {info.confirmCondition === 2 && (
          <div className={styles.grayBox}>
            <div className={styles.differenceMessage}>
              <InfoIcon />
              <div>
                증액하시는 예산으로 인해{' '}
                <strong>예치금액 {thousandsGrouping(info.difference || 0)}원이 추가로 차감</strong>됩니다. <br />
                (보증금 3% + 와디즈 수수료 + VAT 포함)
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
