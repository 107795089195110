import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CountryType } from '@biz/api'
import styles from './CountryFlatList.module.scss'

type CountryFlatListFunction = {
  options: CountryType[]
  value?: string[]
  onChange?: (v: string[]) => void
}
export const CountryFlatList: React.FC<CountryFlatListFunction> = ({ options, value, onChange }) => {
  const checkedList = !value ? [] : value
  return (
    <div className={styles.containCountryFlatList}>
      <div className={styles.checkGroupWrapper}>
        <Checkbox
          indeterminate={checkedList.length > 0 && checkedList.length < options.length}
          checked={options.length === checkedList.length}
          onChange={(e: CheckboxChangeEvent) => onChange?.(e.target.checked ? options.map((n) => n.countryCode) : [])}
        >
          전체 선택
        </Checkbox>
        <Checkbox.Group
          options={options.map((n) => ({
            value: n.countryCode,
            label: n.countryName,
          }))}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
