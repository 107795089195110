import { Input } from 'antd'
import { ReactComponent as SearchIcon } from '@wadiz-frontend/waffle-system-icons/search.svg'
import styles from './NationSearch.module.scss'

type NationSearchFunction = {
  value?: string
  onChange?: (v: string) => void
}
export const NationSearch: React.FC<NationSearchFunction> = ({ value, onChange }) => (
  <div className={styles.container}>
    <Input
      suffix={<SearchIcon />}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      placeholder="국가명을 검색해 주세요"
    />
  </div>
)
