import { useState, useEffect } from 'react'
import { Button } from 'antd'
import { ReactComponent as EditIcon } from '@wadiz-frontend/waffle-system-icons/mode-edit--outline.svg'
import { useCountriesInfoQuery } from '@biz/query'
import { CampaignCaption } from '@biz/ui'
import { NationSelectModal } from './NationSelectModal'
import { SelectedNationList } from './SelectedNationList'
import styles from './NationSelect.module.scss'

type NationSelectFunction = {
  shippingCountries?: string
  value?: string[]
  onChange?: (v: string[]) => void
  onCancel?: () => void
}
export const NationSelect: React.FC<NationSelectFunction> = ({ value, onChange, onCancel, shippingCountries }) => {
  const { data, isPending } = useCountriesInfoQuery()
  const { codeMap } = data || { codeMap: new Map() }
  const [isOpen, setOpen] = useState<boolean>(false)
  useEffect(() => {
    if (value === undefined) {
      setOpen(true)
    }
  }, [value])
  return (
    <>
      <CampaignCaption
        isSub
        tooltip={
          "아래 표시되는 국가는 캠페인을 진행할 국가 목록이에요. 우측의 '변경하기'버튼 선택 시, 캠페인을 진행 할 한국 외 국가를 변경할 수 있어요."
        }
        appendComponent={
          <Button
            className={styles.button}
            type="text"
            onClick={() => setOpen(true)}
            icon={<EditIcon />}
            iconPosition="end"
          >
            {'변경하기'}
          </Button>
        }
      >
        {'타겟 국가'}
      </CampaignCaption>
      <NationSelectModal
        init={value}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false)
          if (!value) {
            onCancel?.()
          }
        }}
        shippingCountries={shippingCountries}
        onResult={(result) => {
          onChange?.(result)
          setOpen(false)
        }}
      />
      {codeMap && (
        <SelectedNationList isPending={isPending} list={(value || []).map((code) => codeMap.get(code)?.name)} />
      )}
    </>
  )
}
