import { useState } from 'react'
import { Tabs } from 'antd'
import type { CountryType, CountryGroupType } from '@biz/api'
import { SupportCountryList } from './SupportCountryList'
import { WholeCountryList } from './WholeCountryList'
import styles from './CountriesTab.module.scss'

type CountriesTabFunction = {
  value?: string[]
  onChange?: (v: string[]) => void
  countries: CountryType[]
  countryGroups: CountryGroupType[]
  supportedCountries: CountryType[]
  codeMap: Map<string, CountryType>
}
export const CountriesTab: React.FC<CountriesTabFunction> = (props) => {
  const [ activeKey, setActiveKey] = useState('available')
  return (
    <Tabs
      className={styles.containCountriesTab}
      activeKey={activeKey}
      onChange={setActiveKey}
      tabBarExtraContent={
        <div className={styles.info}>
          <span>{props?.value?.length || 0}</span>
          {'개 국가 선택'}
        </div>
      }
      items={[
        {
          key: 'available',
          label: '프로젝트 참여 가능 국가',
          children: <SupportCountryList {...props} />,
        },
        {
          key: 'all',
          label: '전체 국가',
          children: <WholeCountryList {...props} />,
        },
      ]}
      size="large"
    />
  )
}

