import { ReactComponent as InfoIcon } from '@wadiz-frontend/waffle-system-icons/info.svg'
import styles from './ChinaAdRestrictionInfo.module.scss'

export const ChinaAdRestrictionInfo = () => (
  <div className={styles.containChinaAdRestrictionInfo}>
    <InfoIcon />
    {'국가별 디지털 광고 규제로 인해 중국 타겟으로는 캠페인을 진행할 수 없어요.'}
  </div>
)

