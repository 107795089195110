import { axios } from '../axios'

export type GeoInfoType = {
  key: string // '2311462'
  name: string // '이스탄불'
  type: string // 'city'
  countryCode: string // 'TR'
  countryName: string // '터키'
  region: string // 'Istanbul Province'
  regionId: number // 3683
  supportsRegion: boolean // true
  supportsCity: boolean // true
}

type ReturnType = {
  ok: boolean
  data: GeoInfoType[] | Partial<{ msg: string; title: string }>
}

type FunctionType = (props: { campId: number; query: string }) => Promise<ReturnType>
export const fetchGeoList: FunctionType = ({ campId, query }) =>
  axios.get(`/api/camp/make02/findFbGeoLists?query=${query}&campId=${campId}`)
