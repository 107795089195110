import { useQuery } from '@tanstack/react-query'
import { toast }  from 'react-toastify'
import { fetchTranslationResult } from '@biz/api'

const makeQueryKey = (id: number) => [
  '@apps/biz/TargetCreatePage/CreativeAssetsContainer/TranslateCover/TranslationResult',
  id,
]

export const useTranslationResultQuery = ({ id }: { id: number }) =>
  useQuery({
    queryKey: makeQueryKey(id),
    queryFn: () =>
      fetchTranslationResult(id).then(
        (res) => {
          if (res?.ok === true) {
            return res.data
          }
        },
        (err) => {
          const status = Math.round((err?.response?.status || 1) / 100)
          if (status === 4 || status === 5) {
            toast.error(`에러가 발생했습니다. 잠시후 다시 시도해주세요. - ${err?.response?.status}`)
          }
        }
      ),
    enabled: Boolean(id),
  })
