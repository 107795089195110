import { axios } from '../axios'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16732913716/-+API#%EB%B2%88%EC%97%AD-%EA%B2%B0%EA%B3%BC-%EB%B0%98%EC%98%81
export type TranslateApplyType = {
  translateResultId: number // long 필수 텍스트 번역 결과 아이디
  applyAdTitle01: boolean // boolean 필수 adTitle01 번역내용 적용 여부
  applyAdTitle02: boolean // boolean 필수 adTitle02 번역내용 적용 여부
  applyAdDesc: boolean // boolean 필수 adDesc 번역내용 적용 여부
  transAfList: {
    // array of object 필수 번역 이미지 파일별 반영 여부
    translateAfId: number // long 필수 번역결과 소재파일 아이디
    applyTransAf: boolean // boolean 필수 번역 소재 적용 여부
  }[]
}
type ResponseType = {
  ok: boolean
  data: {
    msg: string
  }
}
// 401
export const fetchPostTranslationApply = (creationDataId: number, params: TranslateApplyType): Promise<ResponseType> =>
  axios.post(`/api/camp/make02/translate/${creationDataId}/apply`, params)
