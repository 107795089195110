import React from 'react'
import withIcon, { Props } from './utils/withIcon'

function ChevronDownIcon(props: Props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.576 7.924l.848-.848L12 15.652l8.576-8.576.848.848L12 17.35 2.576 7.924z"
      />
    </svg>
  )
}

export default withIcon('ChevronDownIcon')(ChevronDownIcon)
