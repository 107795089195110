import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './TranslateCover.module.scss'

export const TranslateCoverPending: React.FC = () => (
  <div className={styles.containTranslateCover}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />} />
    <div>
      <h5>{'AI 번역 요청 중 이에요'}</h5>
      <div
        className={styles.notify}
      >{`잠시만 기다려 주세요.`}</div>
    </div>
  </div>
)

