import { LoadingBar } from '@common/ui'
import type { CountryType, CountryGroupType } from '@biz/api'
import { CountryGroupCollapse, type CountryGroupCollapseData } from './CountryGroupCollapse'
import styles from './CountryList.module.scss'
import { useRecommendCountriesQuery } from './useRecommendCountriesQuery'

// 아래 배열 순서대로 리스트가 보이게 됨
const groupSortOrder = [
  'africa',
  'asia',
  'europe',
  'north_america',
  'central_america',
  'south_america',
  'oceania',
] as const
type CountryListFunction = {
  value?: string[]
  onChange?: (v: string[]) => void
  countries?: CountryType[]
  countryGroups: CountryGroupType[]
  supportedCountries?: CountryType[]
  codeMap: Map<string, CountryType>
}
export const CountryList: React.FC<CountryListFunction> = ({
  value,
  onChange,
  countries,
  countryGroups,
  supportedCountries,
  codeMap,
}) => {
  // 참여 가능 국가
  const supportedSet = new Set(supportedCountries?.map((n) => n.countryCode))

  // 추천 국가
  const { data, isPending } = useRecommendCountriesQuery()
  const recommendCountries = (data || []) as CountryType[]
  const recommendSet = new Set(recommendCountries.map((n) => n.countryCode))

  // 그룹(대륙)
  const groupSet = Object.fromEntries(countryGroups.map((g) => [g.key, new Set(g.countryCodes)]))
  const groupList = Object.fromEntries(countryGroups.map((g) => [g.key, g])) as Record<string, CountryGroupType>

  // 기타 국가
  const countedCountry = new Set(
    countryGroups
      .filter((n) => groupSortOrder.indexOf(n.key as (typeof groupSortOrder)[number]) > -1)
      .flatMap((n) => n.countryCodes)
  )
  const otherCountries = (countries || []).filter((n) => !countedCountry.has(n.key))
  const otherSet = new Set(otherCountries.map((n) => n.countryCode))

  // 랜더링 데이터
  const collapseData = [
    ...(isPending || recommendCountries.length < 1
      ? []
      : [
          {
            key: '_recommend',
            name: '추천 국가',
            caption: '와디즈를 많이 인지하는 국가 순으로 추천됩니다.',
            options: recommendCountries,
          },
        ]),
    ...groupSortOrder.map((key) => ({
      key,
      name: groupList[key].name,
      caption: '',
      options: groupList[key].countryCodes.map((c) => codeMap.get(c)),
    })),
    {
      key: 'other',
      name: '기타',
      options: otherCountries,
    },
  ] as CountryGroupCollapseData[]

  return (
    <div className={styles.containCountryList}>
      {isPending && ( // 추천 국가
        <div style={{ padding: 16 }}>
          <LoadingBar />
        </div>
      )}
      {!isPending && codeMap && (
        <CountryGroupCollapse
          list={collapseData}
          listSet={{
            _recommend: recommendSet,
            ...Object.fromEntries(groupSortOrder.map((key) => [key, groupSet[key]])),
            other: otherSet,
          }}
          recommendSet={recommendSet}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  )
}
