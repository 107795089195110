import { Modal, Button } from 'antd'
import { ReactComponent as InfoIcon } from '@wadiz-frontend/waffle-system-icons/info.svg'
import styles from './AITranslationConfirmModal.module.scss'

type AITranslationConfirmModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
}
export const AITranslationConfirmModal: React.FC<AITranslationConfirmModalFunction> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      width={400}
      open={isOpen}
      onCancel={onClose}
      title={'AI 번역을 요청하시겠어요?'}
      className={styles.containAITranslationConfirmModal}
      classNames={{
        content: styles.content,
        body: styles.body,
        header: styles.header,
        footer: styles.footer,
      }}
      footer={[
        <Button type="default" size="large" onClick={onClose} style={{ width: 76 }}>
          {'취소'}
        </Button>,
        <Button type="primary" size="large" onClick={onConfirm} style={{ width: 136 }}>
          {'번역 요청하기'}
        </Button>,
      ]}
    >
      <pre>{`와디즈 AI가 제작해주신 소재를 영어로 번역해 드려요. \n번역은 이미지 수에 따라 최대 3분까지 소요될 수 있어요.`}</pre>
      <div className={styles.messageBox}>
        <h6>
          <InfoIcon />
          {'AI 번역을 진행하기 전에 참고해 주세요!'}
        </h6>
        <p>
          {
            'AI 번역 기능은 영어 광고 소재를 쉽게 제작할 수 있도록 돕는 보조 도구에요. 소재의 특성에 따라 번역 결과가 다소 아쉬운 완성도로 제공될 수 있으니 참고하여 진행해 주세요.'
          }
        </p>
      </div>
    </Modal>
  )
}
