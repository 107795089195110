import { Checkbox, type CheckboxProps } from 'antd'
import type { TranslateBeforeInfo, TranslateResultInfo } from '@biz/api'
import { MediaDescriptor } from './MediaDescriptor'
import styles from './TranslationResultModal.module.scss'

export const TranslationResultTable: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <table style={{ tableLayout: 'fixed' }}>
    <thead>
      <tr>
        <th style={{ width: 168 }}>선택 항목</th>
        <th>번역 내용</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
)

export const TranslationResultRow: React.FC<{
  title: React.ReactNode
  org: string
  trans: string
  value: boolean
  onChange: (v: boolean) => void
}> = ({ title, org, trans, value, onChange }) => {
  return (
    <tr>
      <td className={styles.rowHead}>
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange?.(e.target.checked)
          }}
        >
          {title}
        </Checkbox>
      </td>
      <td className={styles.rowContent}>{value ? trans : org}</td>
    </tr>
  )
}

type TransAfInfo = {
  translateAfId: number
  applyTransAf: boolean
}
export const TranslationResultImageRow: React.FC<{
  org: TranslateBeforeInfo[]
  trans: TranslateResultInfo[]
  value: TransAfInfo[]
  onChange: (v: TransAfInfo[]) => void
}> = ({ org, trans, value, onChange }) => {
  const orgSet = Object.fromEntries(org.map((item) => [item.originAfId, item]))
  const onAllChecked: CheckboxProps['onChange'] = (e) => {
    onChange(
      trans.map(({ translateAfId }) => ({
        translateAfId,
        applyTransAf: e.target.checked,
      }))
    )
  }
  const valueSet = Object.fromEntries(value?.map((item) => [item.translateAfId, item.applyTransAf]) || [])
  const checkedListLength = value?.filter((n) => n.applyTransAf).length
  return (
    <tr>
      <td className={styles.rowImageHead}>
        <Checkbox
          onChange={onAllChecked}
          checked={value?.length === checkedListLength}
          indeterminate={checkedListLength !== 0 && checkedListLength < trans.length}
        >
          <span>이미지/영상</span>
          <span>{` ${trans?.length || 0}`}</span>
          <span>개</span>
        </Checkbox>
      </td>
      <td className={styles.rowImageContent}>
        {trans.map((item, index) => (
          <MediaDescriptor
            key={index}
            orgUrl={orgSet[item.originAfId].imageUrl}
            item={item}
            value={valueSet[item.translateAfId]}
            onChange={(checked) =>
              onChange(
                value?.map((n) =>
                  n.translateAfId !== item.translateAfId ? n : { translateAfId: n.translateAfId, applyTransAf: checked }
                )
              )
            }
          />
        ))}
      </td>
    </tr>
  )
}
