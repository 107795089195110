import React from 'react'
import withIcon, { Props } from './utils/withIcon'

function CameraOIcon(props: Props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M19.2 4.8L21.6 8h7.2v17.6H3.2V8h7.2l2.4-3.2h6.4zm.8-1.6h-8L9.6 6.4h-8v20.8h28.8V6.4h-8L20 3.2z" />
      <path d="M16 12a4.8 4.8 0 1 1 0 9.6 4.8 4.8 0 0 1 0-9.6zm0-1.6c-3.535 0-6.4 2.865-6.4 6.4s2.865 6.4 6.4 6.4 6.4-2.865 6.4-6.4-2.865-6.4-6.4-6.4z" />
    </svg>
  )
}

export default withIcon('CameraOIcon')(CameraOIcon)
