import { Button, Modal } from 'antd'
import styles from './PreviewModal.module.scss'

type PreviewModalFunction = {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}
export const PreviewModal: React.FC<PreviewModalFunction> = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      width={400}
      open={isOpen}
      closable={false}
      footer={[
        <Button type="default" size="large" onClick={onClose} style={{ width: 120 }}>
          {'닫기'}
        </Button>,
      ]}
      classNames={{ body: styles.modalBody, footer: styles.modalFooter }}
    >
      {children}
    </Modal>
  )
}
