import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchPostTranslationApply, type TranslateApplyType } from '@biz/api'

type ParameterType = { id: number; params: TranslateApplyType }
type FunctionType = () => UseMutationResult<unknown, unknown, ParameterType, unknown>
export const useTranslateApplyMutation: FunctionType = () =>
  useMutation({
    mutationFn: ({ id, params }) =>
      fetchPostTranslationApply(id, params).then(
        (res) => toast(res?.data?.msg || '반영되었습니다.'),
        (err) => {
          const status = Math.round((err?.response?.status || 1) / 100)
          if (status === 4 || status === 5) {
            toast.error(`에러가 발생했습니다. 잠시후 다시 시도해주세요. - ${err?.response?.status}`)
          }
        }
      ),
  })
