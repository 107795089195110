import { useState } from 'react'
import { Button } from 'antd'
import { useTranslationStatusQuery } from '@biz/query'

import styles from './TranslateButton.module.scss'
import { AITranslationConfirmModal } from './AITranslationConfirmModal'

type TranslateButtonFunction = {
  onTranslate?: () => void
  creationDataId: number
}
export const TranslateButton: React.FC<TranslateButtonFunction> = ({ onTranslate, creationDataId }) => {
  const { data } = useTranslationStatusQuery({ id: creationDataId })
  const [isOpen, setOpen] = useState<boolean>(false)
  return (
    <>
      <Button
        size="small"
        type="primary"
        onClick={() => setOpen(true)}
        className={styles.containTranslateButton}
        disabled={!!data}
      >
        {'AI 영문 번역'}
      </Button>
      <AITranslationConfirmModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false)
          onTranslate?.()
          // if (!!onTranslate) {
          //   console.log('forceRefetch')
          //   forceRefetch()
          // }
        }}
      />
    </>
  )
}
